function waitFor(milliseconds: number) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export type RequestContext = {
  token?: string;
  tenantId?: string;
  organizerId?: string;
};

// eslint-disable-next-line
const DASHBOARD_URL = `${process.env.NEXT_PUBLIC_DASHBOARD_URL}`;

async function fetchWithRetry<T>(url: string, options: any, retry: boolean = false): Promise<T> {
  const maxRetries = 4;
  async function retryWithBackoff<T>(retries: number = 0): Promise<T> {
    try {
      // Make sure we don't wait on the first attempt
      if (retries > 0) {
        // Here is where the magic happens.
        // on every retry, we exponentially increase the time to wait.
        // Here is how it looks for a `maxRetries` = 4
        // (2 ** 1) * 100 = 200 ms
        // (2 ** 2) * 100 = 400 ms
        // (2 ** 3) * 100 = 800 ms
        const timeToWait = 2 ** retries * 100;
        console.log(`waiting for ${timeToWait}ms...`);
        await waitFor(timeToWait);
      }

      if (typeof window !== 'undefined') {
        options.credentials = 'include';
      }

      const response = await fetch(url, options);
      if (response.status === 401) {
        console.log('refreshing token');
      }

      // refresh token on 401 and 403 and retry
      if ([401, 403].includes(response.status)) {
        const refreshResponse = await fetch(`${DASHBOARD_URL}/api/refresh`, {
          method: 'GET',
          headers: options.headers,
        });

        console.log('retrying');

        if (refreshResponse.status === 401) {
          throw new Error('auth:refresh_token_expired');
        }

        if (refreshResponse.status === 200) {
          return retryWithBackoff(retries + 1);
        }

        throw new Error(`Refresh request failed with status code ${refreshResponse.status}`);
      }

      if (response.status === 200) {
        return await response.json();
      }

      if ([408, 502, 503, 504].includes(response.status) && retry && retries < maxRetries) {
        return retryWithBackoff(retries + 1);
      }

      throw new Error(`Request failed with status code ${response.status}`);
    } catch (e: any) {
      // only retry if we didn't reach the limit
      // otherwise, let the caller handle the error

      console.warn(
        `${
          e.message !== 'auth:refresh_token_expired' ? 'Max retries reached: ' + e.message : 'Refresh token expired'
        }. Bubbling the error up`,
      );

      if (e.message === 'auth:refresh_token_expired') {
        actions.unauthorizedCallback();
      }

      throw e;
    }
  }

  return await retryWithBackoff<T>(0);
}

function getHeadersFromContext(context: RequestContext) {
  const headers: { [x: string]: string } = {
    'Content-Type': 'application/json',
  };

  if (context.token) {
    headers['Authorization'] = `Bearer ${context.token}`;
  }

  if (context.tenantId) {
    headers['X-Tenant-Id'] = context.tenantId;
  }

  if (context.organizerId) {
    headers['X-Organizer-Id'] = context.organizerId;
  }

  return headers;
}

export async function post<T, B>(context: RequestContext, url: string, body: B, retry: boolean = true): Promise<T> {
  return await fetchWithRetry<T>(
    url,
    {
      method: 'POST',
      headers: getHeadersFromContext(context),
      body: JSON.stringify(body),
    },
    retry,
  );
}

export async function put<T, B>(context: RequestContext, url: string, body: B, retry: boolean = true): Promise<T> {
  return await fetchWithRetry<T>(
    url,
    {
      method: 'PUT',
      headers: getHeadersFromContext(context),
      body: JSON.stringify(body),
    },
    retry,
  );
}

export async function get<T>(context: RequestContext, url: string, params?: any, retry: boolean = true): Promise<T> {
  if (params) {
    url = `${url}?${new URLSearchParams(params).toString()}`;
  }
  return await fetchWithRetry<T>(
    url,
    {
      method: 'GET',
      headers: getHeadersFromContext(context),
    },
    retry,
  );
}

export async function del<T>(context: RequestContext, url: string, params?: any, retry: boolean = true): Promise<T> {
  if (params) {
    url = `${url}?${new URLSearchParams(params).toString()}`;
  }
  return await fetchWithRetry<T>(
    url,
    {
      method: 'DELETE',
      headers: getHeadersFromContext(context),
    },
    retry,
  );
}

const actions = {
  unauthorizedCallback: () => {},
  get,
  post,
  put,
  del,
};

export default actions;
