import { useRouter, useSearchParams } from 'next/navigation';
import { create } from 'zustand';

import { useFetch } from '../helpers/client/useFetch';

// user state store
const initialState = {
  users: [],
  user: undefined,
  currentUser: undefined,
};

const userStore = create<IUserStore>(() => initialState);

// eslint-disable-next-line
const DASHBOARD_URL = `${process.env.NEXT_PUBLIC_DASHBOARD_URL}`;

export function useUserService(): IUserService {
  //const alertService = useAlertService();
  const fetch2 = useFetch();
  const router = useRouter();
  const searchParams = useSearchParams();
  const { users, user, currentUser } = userStore();

  return {
    users,
    user,
    currentUser,
    login: async (email, password) => {
      //alertService.clear();
      try {
        const currentUser = await fetch2.post<{ success: Boolean; user: IUser }, { email: string; password: string }>(
          {},
          `${DASHBOARD_URL}/api/login`,
          { email, password },
        );

        userStore.setState({ ...initialState, currentUser: currentUser.user });

        // get return url from query parameters or default to '/'
        const returnUrl = searchParams.get('returnUrl') || '/';
        router.push(returnUrl);
      } catch (error: any) {
        //alertService.error(error);
      }
    },
    logout: async () => {
      await fetch('/api/logout', { method: 'POST' });
      router.push('/account/login');
    },
    /*register: async (user) => {
      try {
        await fetch.post('/api/account/register', user);
        alertService.success('Registration successful', true);
        router.push('/account/login');
      } catch (error: any) {
        alertService.error(error);
      }
    },*/
    /*getAll: async () => {
      userStore.setState({ users: await fetch.get('/api/users') });
    },
    getById: async (id) => {
      userStore.setState({ user: undefined });
      try {
        userStore.setState({ user: await fetch.get(`/api/users/${id}`) });
      } catch (error: any) {
        alertService.error(error);
      }
    },*/
    getCurrent: async () => {
      if (!currentUser) {
        userStore.setState({ currentUser: await fetch2.get({}, `${DASHBOARD_URL}/account/whoami`) });
      }
    },
    /*create: async (user) => {
      await fetch.post('/api/users', user);
    },*/
    /*update: async (id, params) => {
      await fetch.put(`/api/users/${id}`, params);

      // update current user if the user updated their own record
      if (id === currentUser?.id) {
        userStore.setState({ currentUser: { ...currentUser, ...params } });
      }
    },*/
    /*delete: async (id) => {
      // set isDeleting prop to true on user
      userStore.setState({
        users: users!.map((x) => {
          if (x.id === id) {
            x.isDeleting = true;
          }
          return x;
        }),
      });

      // delete user
      const response = await fetch.delete(`/api/users/${id}`);

      // remove deleted user from state
      userStore.setState({ users: users!.filter((x) => x.id !== id) });

      // logout if the user deleted their own record
      if (response.deletedSelf) {
        router.push('/account/login');
      }
    },*/
  };
}

// interfaces

interface IUser {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  contexts: {
    system: { isSuperAdmin: boolean };
    tenant: { [x: string]: { isSuperAdmin: boolean } };
    organizers: { [x: string]: { isSuperAdmin: boolean } };
  };
  resources: any[];
  isDeleting?: boolean;
}

interface IUserStore {
  users?: IUser[];
  user?: IUser;
  currentUser?: IUser;
}

interface IUserService extends IUserStore {
  // eslint-disable-next-line
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  //register: (user: IUser) => Promise<void>;
  //getAll: () => Promise<void>;
  //getById: (id: string) => Promise<void>;
  getCurrent: () => Promise<void>;
  //create: (user: IUser) => Promise<void>;
  //update: (id: string, params: Partial<IUser>) => Promise<void>;
  //delete: (id: string) => Promise<void>;
}
