import { useRouter } from 'next/navigation';

import apiClient from '../../lib/apiClient';

export function useFetch() {
  const router = useRouter();

  apiClient.unauthorizedCallback = () => {
    router.push('/account/login');
  };

  return {
    get: apiClient.get,
    post: apiClient.post,
    put: apiClient.put,
    delete: apiClient.del,
  };
}
